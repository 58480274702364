import { cn } from "@/lib/utils";
import type { Category, Shop, Tag } from "@/types";
import { ChevronRight, ChevronRightCircle } from "lucide-react";
import queryString from "query-string";

interface Props {
  shop: Shop;
  shopCategories?: Category[];
  shopTags?: Tag[];
}

const CategoriesNav = ({ shop, shopCategories = [], shopTags = [] }: Props) => {
  const generateCategoryLink = (category: Category) => {
    const url = queryString.stringifyUrl(
      {
        url: `/shops/${shop.slug}/products`,
        query: {
          categories: [category.slug],
        },
      },
      {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: "comma",
      }
    );

    return url;
  };

  const generateTagLink = (tag: Tag) => {
    const url = queryString.stringifyUrl(
      {
        url: `/shops/${shop.slug}/products`,
        query: {
          tags: [tag.slug],
        },
      },
      {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: "comma",
      }
    );

    return url;
  };

  return (
    <nav className="container mx-auto px-2">
      <div className="border-t border-kp-black/10 py-0.5 relative">
        <ul className="flex justify-start md:justify-center space-x-2 md:space-x-6 overflow-x-auto whitespace-nowrap py-0.5 scrollbar-hide">
          {shopCategories.map((item) => (
            <li
              key={"nav-categories-" + item.id}
              data-id={item.id}
              className="flex-shrink-0 hidden">
              <a
                href={generateCategoryLink(item)}
                className={cn(
                  "flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors",
                  "hover:bg-kp-black/10"
                )}>
                {item.name}
              </a>
            </li>
          ))}

          {shopTags.map((item) => (
            <li
              key={"nav-tags-" + item.id}
              data-id={item.id}
              className={cn("flex-shrink-0", {
                hidden: item.slug === "uber",
              })}>
              <a
                href={generateTagLink(item)}
                className={cn(
                  "flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors",
                  "hover:bg-kp-black/10"
                )}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>

        <div className="absolute -right-2 top-0 bottom-0 grid items-center">
          <ChevronRightCircle className="size-6 fill-black text-white" />
        </div>
      </div>
    </nav>
  );
};

export default CategoriesNav;
